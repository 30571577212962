<section class="landing-greetings">
  <div class="landing-greetings__container">
    <fbr-landing-header [locale]="locale"
                        (signIn)="signIn.emit()"/>
    <div class="landing-greetings__wrapper">
      <div class="landing-greetings__content-wrapper">
        <h1 class="landing-greetings__title">
          {{'landing.greetings.title' | translate}}
        </h1>
        <div class="landing-greetings__mobile-img-container">
          <img ngSrc="landing-main-mobile2.png"
               alt=""
               priority="true"
               width="400"
               height="223"
               class="landing-greetings__mobile-img">
        </div>
        <div class="landing-greetings__description">
          {{'landing.greetings.subtitle' | translate}}
        </div>

        <button mat-stroked-button class="landing__button" (click)="signIn.emit()">
          {{'landing.greetings.start-button' | translate}} <span class="landing__button-icon">&#8594;</span>
        </button>
      </div>
      <div class="landing-greetings__img-container">
        <img class="landing-greetings__tablet-img"
             ngSrc="landing-main-tablet2.png"
             alt=""
             ngSrcset="1x, 2x"
             priority="true"
             width="736"
             height="418">

        <img class="landing-greetings__img"
             ngSrc="landing-main-desktop2.png"
             alt=""
             ngSrcset="1x, 2x"
             priority="true"
             width="895"
             height="575">
      </div>
    </div>
  </div>
</section>
