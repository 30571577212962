<div class="landing-menu">
  <button class="landing-menu__close-button" mat-icon-button (click)="closeMenu()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="landing-menu__logo-container">
    <div class="landing-menu__logo-wrapper">
      <img src="assets/images/logo/logo-grey.svg">
    </div>
    Freebetrange
  </div>

  <div class="landing-menu__link-container">

    <a class="landing-menu__link"
       [href]="locale | anchorHref : 'pricing'"
       (click)="closeMenu()">
      {{'landing.menu.pricing-item' | translate}}
    </a>
    <a class="landing-menu__link"
       [href]="locale | anchorHref : 'features'"
       (click)="closeMenu()">
      {{'landing.menu.features-item' | translate}}
    </a>
    <a class="landing-menu__link"
       [href]="locale | localizedLinks : ExternalLinksType.MANUAL"
       target="_blank">{{'landing.menu.manual-item' | translate}}</a>
    <a class="landing-menu__link"
       [href]="locale | localizedLinks : ExternalLinksType.STABLES"
       target="_blank">{{'landing.menu.stables-item' | translate}}</a>
  </div>

  <div class="landing-menu__buttons-container">
    <button class="landing__button landing-menu__sign-in-button" mat-stroked-button (click)="signIn()">
      {{'landing.menu.sign-in-button' | translate}}
    </button>
    <button class="landing__button" mat-stroked-button (click)="signIn()">
      {{'landing.menu.sign-up-button' | translate}}
    </button>
  </div>
</div>
