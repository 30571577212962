<section class="landing-partners">

  <div class="landing-partners__containers">
    @for (partner of partners; track $index;) {
      @if (!partner.languages || partner.languages.includes(locale)) {
        <a class="landing-partners__img-container"
           [href]="!!partner.externalLinkType ? (locale | localizedLinks : partner.externalLinkType) : partner.href"
           [style.height]="(isLargeBreakpoint$ | async) ? partner.height : partner.mobileHeight"
           target="_blank">
          <img class="landing-partners__img" [src]="partner.logoUrl" [alt]="partner.alt">
          <img class="landing-partners__active-img" [src]="partner.activeLogoUrl" [alt]="partner.alt">
        </a>
      }
    }
  </div>

  <span class="landing-partners__link">
    {{'landing.partners.link-description' | translate}}
    <a [href]="locale | localizedLinks : ExternalLinksType.BECOME_PARTNER">
      {{'landing.partners.link-href' | translate}}
      <span class="landing-partners__link-icon">&#8594;</span>
    </a>
  </span>
</section>
