import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {LocalStorageKeys} from '../enum/local-storage-keys.enum';
import {LocalStorage} from '../tokens/local-storage';
import {LanguageService} from '../services/language.service';

@Injectable()
export class TranslateInterceptor implements HttpInterceptor {
  constructor(
    @Inject(LocalStorage) private readonly localStorage: Storage,
    @Inject(LanguageService) private readonly languageService: LanguageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang = this.languageService.currentLanguage();
    if (this.localStorage.getItem(LocalStorageKeys.Language)) {
      return next.handle(req.clone({
        headers: req.headers.set('Content-Language', lang)
      }));
    } else {
      return next.handle(req);
    }
  }
}
