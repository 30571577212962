import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ILandingImageOption} from '../../interfaces/landing-image-option.interface';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'fbr-landing-ranges-source',
  templateUrl: './landing-ranges-source.component.html',
  styleUrls: ['./landing-ranges-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    TranslateModule,
    NgOptimizedImage
  ]
})
export class LandingRangesSourceComponent {

  sourcesOptions: ILandingImageOption[] = [{
    title: 'landing.ranges-source.create.title',
    mobileImgUrl: 'landing-create-tablet2.png',
    imgUrl: 'landing-create-desktop2.png',
    description: 'landing.ranges-source.create.description',
    alt: 'Poker range builder'
  }, {
    title: 'landing.ranges-source.import.title',
    mobileImgUrl: 'landing-import-tablet.png',
    imgUrl: 'landing-import-desktop.png',
    description: 'landing.ranges-source.import.description',
    alt: 'Import preflop ranges from flopzilla, piosolver'
  }, {
    title: 'landing.ranges-source.buy.title',
    mobileImgUrl: 'landing-load-desktop.png',
    imgUrl: 'landing-load-desktop.png',
    description: 'landing.ranges-source.buy.description',
    alt: 'Buy ready-made preflop chart packages'
  }];

  selectedOption: ILandingImageOption = this.sourcesOptions[0];

  constructor() { }

  setOption(option: ILandingImageOption): void {
    if (option) {
      this.selectedOption = option;
    }
  }

}
