import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output} from '@angular/core';
import {ILandingPricingCard} from '../landing-pricing-card/landing-pricing-card.interface';
import {CommonModule} from '@angular/common';
import {LandingPricingCardComponent} from '../landing-pricing-card/landing-pricing-card.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {TranslateModule} from '@ngx-translate/core';

export interface IPricingPeriodOption {
  title: string;
  pricePerMonth: number;
  price: number;
  percentNumber: number;
}

@Component({
  selector: 'fbr-landing-pricing',
  templateUrl: './landing-pricing.component.html',
  styleUrls: ['./landing-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LandingPricingCardComponent,
    MatButtonToggleModule,
    TranslateModule
  ]
})
export class LandingPricingComponent {
  @Output() signIn: EventEmitter<boolean> = new EventEmitter<boolean>();

  freeCard: ILandingPricingCard = {
    pro: false,
    features: [
      'landing.pricing.free-card.features.1', 'landing.pricing.free-card.features.2',
      'landing.pricing.free-card.features.3', 'landing.pricing.free-card.features.4',
      'landing.pricing.free-card.features.5', 'landing.pricing.free-card.features.6',
      'landing.pricing.free-card.features.7'
    ],
    pricePerMonth: null,
    price: null,
    percentNumber: null,
    signUpText: 'landing.pricing.free-card.sign-text'
  };

  proCard: ILandingPricingCard = {
    pro: true,
    features: [
      'landing.pricing.pro-card.features.1', 'landing.pricing.pro-card.features.2',
      'landing.pricing.pro-card.features.3', 'landing.pricing.pro-card.features.4',
      'landing.pricing.pro-card.features.10', 'landing.pricing.pro-card.features.5',
      'landing.pricing.pro-card.features.6', 'landing.pricing.pro-card.features.7',
      'landing.pricing.pro-card.features.8', 'landing.pricing.pro-card.features.9'
    ],
    pricePerMonth: 9,
    price: null,
    percentNumber: null,
    signUpText: 'landing.pricing.pro-card.sign-text'
  };

  proOptions: IPricingPeriodOption[] = [{
    title: 'landing.pricing.periods.month',
    pricePerMonth: 9,
    price: null,
    percentNumber: null,
  }, {
    title: 'landing.pricing.periods.year',
    pricePerMonth: 4.95,
    price: 59.4,
    percentNumber: 45,
  },  {
    title: 'landing.pricing.periods.3years',
    pricePerMonth: 2.75,
    price: 99,
    percentNumber: 70,
  }];

  selectedOption: IPricingPeriodOption = this.proOptions[1];

  constructor(private cd: ChangeDetectorRef) { }

  changePricingPeriod(value: IPricingPeriodOption): void {
    if (value) {
      this.selectedOption = value;
      this.proCard = Object.assign(
        {},
        this.proCard,
        {
          pricePerMonth: value.pricePerMonth,
          price: value.price,
          percentNumber: value.percentNumber,
        }
      );
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }
}
