import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';
import {LANDING_LOCALE, MENU_OVERLAY_REF, SIGN_IN_OUTPUT} from '../landing-greetings/landing-greetings.component';
import {Overlay} from '@angular/cdk/overlay';
import {LandingMobileMenuComponent} from '../landing-mobile-menu/landing-mobile-menu.component';
import {ExternalLinksType} from '../../../../common/enum/external-links.enum';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../../common/pipes/common-pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';

@Component({
  selector: 'fbr-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CommonPipesModule,
    TranslateModule,
    MatIconModule,
    MatIconButton
  ]
})
export class LandingHeaderComponent {
  @Input() locale: string;
  @Output() signIn = new EventEmitter();
  ExternalLinksType = ExternalLinksType;

  constructor(private overlay: Overlay) { }

  openMenu(): void {
    const overlayRef = this.overlay.create({
      width: '100%',
      height: '100%'
    });
    const userProfilePortal = new ComponentPortal(LandingMobileMenuComponent, null, Injector.create({
      providers: [
        {provide: MENU_OVERLAY_REF, useValue: overlayRef},
        {provide: SIGN_IN_OUTPUT, useValue: () => this.signIn.emit()},
        {provide: LANDING_LOCALE, useValue: this.locale}
      ]
    }));
    overlayRef.attach(userProfilePortal);
  }

}
