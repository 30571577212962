import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {DEFAULT_LOCALE, LanguageService} from '../services/language.service';

export const LanguageRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const languageService = inject(LanguageService);
  const router  = inject(Router);
  return languageService.initLanguage()
    .then((lang) => router.parseUrl(lang))
    .catch(() => router.parseUrl(DEFAULT_LOCALE));
};
