import {ApplicationConfig, importProvidersFrom, TransferState} from '@angular/core';
import {provideRouter, TitleStrategy} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import {WINDOW_PROVIDERS} from '../common/services/windowRef.service';
import {TranslatedTitleStrategyProvider} from '../common/providers/translated-title-strategy.provider';
import {provideImgixLoader} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {LocalStorage} from '../common/tokens/local-storage';
import {TranslateCachedSSRLoader} from '../common/loaders/translateCachedSSR.loader';
import {CookieService} from 'ngx-cookie-service';
import {MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/button-toggle';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/slide-toggle';
import {TranslateInterceptor} from '../common/interceptors/translate.interceptor';
import {UpdateUserInterceptor} from '../common/interceptors/update-user.interceptor';

export function createTranslateLoader(http: HttpClient, transferState: TransferState): TranslateCachedSSRLoader {
  return new TranslateCachedSSRLoader(http, transferState, './assets/i18n/',  `/lang.json`);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi()
    ),
    provideClientHydration(),
    ...WINDOW_PROVIDERS,
    {
      provide: TitleStrategy, useClass: TranslatedTitleStrategyProvider
    },
    provideImgixLoader('https://freebetrange.imgix.net/'),
    importProvidersFrom([
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient, TransferState]
        }
      }),
      StoreModule.forRoot(),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({
        name: 'NgRx Freebetrange Store App',
        logOnly: environment.production,
      })
    ]),
    { provide: LocalStorage, useFactory: () => (window) ? window.localStorage : {}},
    CookieService,
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: {hideSingleSelectionIndicator: true}},
    { provide: MAT_SELECT_CONFIG, useValue: {hideSingleSelectionIndicator: true}},
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: {hideIcon: true}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UpdateUserInterceptor,
      multi: true
    }
  ]
};
