<section class="landing-features">
  <h2 class="landing__title">{{'landing.features.title' | translate}}</h2>

  <div class="landing-features__container">
    <section class="landing-features__section">
      <div class="landing-features__section-title">
        <img class="landing-features__section-logo"
             src="assets/images/landing/landing-quick-share.svg"
             alt="">
        {{'landing.features.sections.1.title' | translate}}</div>
      <div class="landing-features__section-description">
        {{'landing.features.sections.1.description' | translate}}
      </div>
    </section>

    <section class="landing-features__section">
      <div class="landing-features__section-title">
        <img class="landing-features__section-logo"
             src="assets/images/landing/landing-configuration-feature.svg"
             alt="">
        {{'landing.features.sections.2.title' | translate}}
      </div>
      <div class="landing-features__section-description">
        {{'landing.features.sections.2.description' | translate}}
      </div>
    </section>

    <section class="landing-features__section">
      <div class="landing-features__section-title">
        <img class="landing-features__section-logo"
             src="assets/images/landing/landing-shortdeck-feature.svg"
             alt="">
        {{'landing.features.sections.3.title' | translate}}
      </div>
      <div class="landing-features__section-description">
        {{'landing.features.sections.3.description' | translate}}
      </div>
    </section>

    <section class="landing-features__section">
      <div class="landing-features__section-title">
        <img class="landing-features__section-logo"
             src="assets/images/landing/landing-theme-feature.svg"
             alt="">
        {{'landing.features.sections.4.title' | translate}}
      </div>
      <div class="landing-features__section-description">
        {{'landing.features.sections.4.description' | translate}}
      </div>
    </section>
  </div>
</section>
