<footer class="landing-footer">
  <div class="landing-footer__row">
    <div class="landing-footer__copyright-container">
      <div class="landing-footer__logo-container">
        <img class="landing-footer__logo-img" src="assets/images/logo/logo-grey.svg" alt="">Freebetrange
      </div>

      <span class="landing-footer__copyright"> © {{currentYear}} {{'landing.footer.copyright-text' | translate}}</span>
    </div>

    <div class="landing-footer__dis-container">
      <a class="landing-footer__discord-logo"
         href="https://discord.com/invite/9tRkAMDrg4"
         target="_blank">
        <img class="landing-footer__discord-active-img"
             src="assets/images/socials/discord-flat-logo-colored.svg" alt="discord social">
        <img class="landing-footer__discord-img"
             src="assets/images/socials/discord-flat-logo.svg" alt="youtube social">
      </a>
      <a class="landing-footer__youtube-logo"
          href="https://www.youtube.com/channel/UCuYN9S4anfw5KyB7n2ORlGQ"
         target="_blank">
        <img class="landing-footer__youtube-active-img" src="assets/images/socials/youtube-logo-colored.svg" alt="youtube social">
        <img class="landing-footer__youtube-img" src="assets/images/socials/youtube-logo.svg" alt="youtube social">
      </a>

      <a class="landing-footer__instagram-logo"
         [href]="selectedLocale() === 'es' ? 'https://instagram.com/freebetrange_es/' : 'https://www.instagram.com/freebetrange/'"
         target="_blank">
        <img class="landing-footer__instagram-active-img" src="assets/images/socials/instagram-colored.svg" alt="instagram social">
        <img class="landing-footer__instagram-img" src="assets/images/socials/instagram.svg" alt="instagram social">
      </a>

      <a class="landing-footer__instagram-logo"
         href="https://www.facebook.com/Freebetrange"
         target="_blank">
        <img class="landing-footer__instagram-active-img" src="assets/images/socials/facebook-colored.svg" alt="facebook social">
        <img class="landing-footer__instagram-img" src="assets/images/socials/facebook.svg" alt="facebook social">
      </a>
    </div>
  </div>
  <div class="landing-footer__row landing-footer__row_wrap">
    <div class="landing-footer__language-selector">
      <mat-select
          [value]="selectedLanguage()"
          (valueChange)="localeChange.emit($event.locale)"
          panelClass="landing__language-select-panel"
          [panelWidth]="null">
        <mat-select-trigger>
          <div class="landing-footer__language-selector-trigger">
            <div class="fbr-icon language-icon"></div> {{selectedLanguage()?.title | translate}}
          </div>
        </mat-select-trigger>

        @for (language of languages(); track $index) {
          <mat-option [value]="language">
            {{language.title}}
          </mat-option>
        }
      </mat-select>
    </div>

    <div class="landing-footer__legal-links-container">
      @for (link of legalFooterLinks; track $index) {
        <a [href]="link.href" class="landing-footer__link">
          {{link.title | translate}}
        </a>
      }
    </div>

    <div class="landing-footer__contacts-links-container">
      @for (link of contactFooterLinks; track $index) {
        <a [href]="selectedLocale() | localizedLinks : link.linkType"
           class="landing-footer__link">
          {{link.title | translate}}
        </a>
      }
    </div>

    <div class="landing-footer__payment-methods">
      <img src="assets/images/payment-methods/visa.svg" alt="visa payment method">
      <img src="assets/images/payment-methods/mastercard.svg" alt="mastercard payment method">
      <img src="assets/images/payment-methods/skrill.svg" alt="skrill payment method">
      <img src="assets/images/payment-methods/bitcoin.svg" alt="bitcoin payment method">
      <img src="assets/images/payment-methods/usdt.svg" alt="usdt payment method">
    </div>
  </div>

</footer>
