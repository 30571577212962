import {Component, OnInit} from '@angular/core';
import {DEFAULT_LOCALE, LanguageService} from '../../common/services/language.service';
import {Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-language-detector',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [
    RouterOutlet
  ]
})
export class LanguageDetectorComponent implements OnInit {

  constructor(private languageService: LanguageService,
              private router: Router) { }

  ngOnInit(): void {
 /*   const language = this.languageService.getLanguage();
    const supportedLanguage = this.languageService.supportedLanguages.find(l => l.locale === language);
    if (supportedLanguage) {
     this.router.navigate([supportedLanguage.locale], { queryParamsHandling: "merge" });
    } else {
      this.router.navigate([DEFAULT_LOCALE], { queryParamsHandling: "merge" });
    }*/
  }

}
