import {Component, inject, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {environment} from '../environments/environment';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {LocalStorageKeys} from '../common/enum/local-storage-keys.enum';
import {WindowRef} from '../common/services/windowRef.service';
declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet]
})
export class AppComponent {
  router = inject(Router);
  matIconRegistry = inject(MatIconRegistry);
  domSanitizer = inject(DomSanitizer);
  platformId = inject(PLATFORM_ID);
  window = inject(WindowRef)

  ngOnInit(): void {
    this._registerIcons();
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd && environment.production) {
          gtag('config', 'UA-171116529-1',
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      });
      const urlParams = new URLSearchParams((this.window.nativeWindow as Window).location.search);
      const refParam = urlParams.get('rc');
      if (refParam) {
        localStorage.setItem(LocalStorageKeys.RefCode, refParam);
      }
    }

  }

  private _registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'fbr-range',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-range.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fbr-add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fbr-add-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-add-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fbr-folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-folder.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fbr-arrow-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-arrow-up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fbr-arrow-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-arrow-down.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'fbr-flask',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-flask.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'fbr-flask-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-flask-active.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'fbr-subrange',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fbr-subrange.svg')
    );
  }
}

