<div class="landing">
  @if (userStore.inited() === 'in-progress') {
    <fbr-loader/>
  }
  <div class="landing__greetings-wrapper">
    <fbr-landing-greetings [locale]="currentLanguage()"
                           (signIn)="signIn()"/>
  </div>

  <div id="pricing" class="landing__pricing-wrapper">
    <fbr-landing-pricing (signIn)="signIn($event)"/>
  </div>

  <div id="features" class="landing__ranges-source-wrapper">
    <fbr-landing-ranges-source/>
  </div>

  <div class="landing__strategies-wrapper">
    <fbr-landing-strategies/>
  </div>

  <div id="landingFeatures" class="landing__features-wrapper">
    <fbr-landing-features/>
  </div>

  <div class="landing__faq-wrapper">
    <fbr-landing-faq [locale]="currentLanguage()"/>
  </div>

  <div class="landing__partners-wrapper">
    <fbr-landing-partners [locale]="currentLanguage()"/>
  </div>

  <div class="landing__footer-wrapper">
    <fbr-landing-footer [languages]="languages"
                        [selectedLocale]="currentLanguage()"
                        (localeChange)="setLocale($event)"/>
  </div>
</div>
