import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {TranslateModule} from '@ngx-translate/core';
import {CommonPipesModule} from '../../../../common/pipes/common-pipes.module';
import {ExternalLinksType} from '../../../../common/enum/external-links.enum';

@Component({
  selector: 'fbr-landing-faq',
  templateUrl: './landing-faq.component.html',
  styleUrls: ['./landing-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    TranslateModule,
    CommonPipesModule,
    NgOptimizedImage
  ]
})
export class LandingFAQComponent {
  @Input() locale: string;

  ExternalLinksType = ExternalLinksType;

  constructor() { }

}
