import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  OnInit,
  PLATFORM_ID,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {UserStore} from '../../common/custom-stores/user.store';
import {LanguageService} from '../../common/services/language.service';
import {ILanguage} from '../../common/interfaces/language.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {TFbrLang} from '../../common/enum/lang.enum';
import {AsyncPipe, CommonModule, isPlatformBrowser} from '@angular/common';
import {LandingGreetingsComponent} from './components/landing-greetings/landing-greetings.component';
import {LandingPricingComponent} from './components/landing-pricing/landing-pricing.component';
import {LandingRangesSourceComponent} from './components/landing-ranges-source/landing-ranges-source.component';
import {LandingStrategiesComponent} from './components/landing-strategies/landing-strategies.component';
import {LandingFeaturesComponent} from './components/landing-features/landing-features.component';
import {LandingFAQComponent} from './components/landing-faq/landing-faq.component';
import {LandingPartnersComponent} from './components/landing-partners/landing-partners.component';
import {LandingFooterComponent} from './components/landing-footer/landing-footer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LocalStorageKeys} from '../../common/enum/local-storage-keys.enum';
import {LoaderComponent} from '../../common/components/loader/loader.component';
import {WINDOW, WindowRef} from '../../common/services/windowRef.service';
import {TRangePages} from '../ranges/enums/range-pages.enum';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LandingGreetingsComponent,
    LandingPricingComponent,
    LandingRangesSourceComponent,
    LandingStrategiesComponent,
    LandingFeaturesComponent,
    LandingFAQComponent,
    LandingPartnersComponent,
    LandingFooterComponent,
    AsyncPipe,
    MatProgressSpinnerModule,
    LoaderComponent
  ]
})
export class LandingComponent implements OnInit{
  userStore = inject(UserStore);
  currentLanguage: Signal<TFbrLang> = this.languageService.currentLanguage;
  languages: ILanguage[] = this.languageService.supportedLanguages;
  userInit= this.userStore.inited;
  activatedRoute = inject(ActivatedRoute);

  constructor(
    private readonly languageService: LanguageService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) public readonly platformId: string) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._redirectAfterLogin();
    }
  }

  signIn(tryPro = false): void {
    if (tryPro) {
      localStorage.setItem(LocalStorageKeys.TryPro, 'true');
    }
    this.userStore.login();
  }


  setLocale(locale: string): void {
    this.languageService.setLanguage(locale as TFbrLang)
    this.router.navigateByUrl('').then(() => {
      window.scroll(0, 20);
    });
  }

  private _redirectAfterLogin(): void {
    const location = window.location.pathname;
    const isLocationPlain = this.languages.find(l => `/${l.locale}` === location);
    if (isLocationPlain) {
      const page = window?.screen?.width < 768 ? TRangePages.Viewer :TRangePages.Editor;
      this.router.navigate(['ranges', page], {relativeTo: this.activatedRoute})
    }
  }
}
