<section class="landing-strategies">
  <h2 class="landing__title">
    {{'landing.strategies.title' | translate}}
  </h2>

  <mat-button-toggle-group class="landing__button-group landing-strategies__button-group"
                           [value]="selectedOption"
                           (valueChange)="setOption($event)">
    @for (option of strategiesOptions; track $index) {
      <mat-button-toggle [value]="option">{{option.title | translate}}</mat-button-toggle>
    }
  </mat-button-toggle-group>


  @for (option of strategiesOptions; track $index) {
    <div [style.visibility]="option === selectedOption ? 'visible' : 'hidden'"
         [style.position]="option === selectedOption ? 'static' : 'absolute'"
         class="landing-strategies__mobile-img-wrapper">
      <img
          class="landing-strategies__mobile-img"
          [ngSrc]="option.mobileImgUrl"
          ngSrcset="1x, 2x"
          [alt]="option.alt"
          width="736"
          height="530">
    </div>
  }



  <div class="landing-strategies__description">
    <div [innerHtml]="selectedOption?.description | translate"></div>
  </div>

  @for (option of strategiesOptions; track $index) {
    <div [style.visibility]="option === selectedOption ? 'visible' : 'hidden'"
         class="landing-strategies__img-wrapper">
      <img class="landing-strategies__img"
           [ngSrc]="option.imgUrl"
           ngSrcset="1x, 2x"
           [alt]="option.alt"
           width="1010"
           height="580">
    </div>
  }

</section>
