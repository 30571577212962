import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  InjectionToken,
  Input,
  Output
} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {LandingHeaderComponent} from '../landing-header/landing-header.component';

export const MENU_OVERLAY_REF = new InjectionToken('menuOverlayRef');
export const SIGN_IN_OUTPUT = new  InjectionToken('signInOutput');
export const LANDING_LOCALE = new  InjectionToken('landingLocale');

@Component({
  selector: 'fbr-landing-greetings',
  templateUrl: './landing-greetings.component.html',
  styleUrls: ['./landing-greetings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    NgOptimizedImage,
    LandingHeaderComponent
  ]
})
export class LandingGreetingsComponent {
  @Input() locale: string;
  @Output() signIn = new EventEmitter();

  constructor() { }

}
