<section class="landing-pricing">
  <h2 class="landing__title">{{'landing.pricing.title' | translate}}</h2>
  <div class="landing-pricing__container">
    <div class="landing-pricing__free-card-container">
      <fbr-landing-pricing-card [card]="freeCard"
                                (signIn)="signIn.emit(false)"/>
    </div>

    <div class="landing-pricing__pro-card-container">
      <mat-button-toggle-group class="landing__button-group landing-pricing__button-group"
                               [value]="selectedOption"
                               (valueChange)="changePricingPeriod($event)">
        @for (option of proOptions; track $index) {
          <mat-button-toggle [value]="option">{{option.title | translate}}</mat-button-toggle>
        }
      </mat-button-toggle-group>
      <fbr-landing-pricing-card [card]="proCard"
                                (signIn)="signIn.emit(true)"/>
    </div>


  </div>
</section>
