<header class="landing-header">
  <div>
    <img class="landing-header__logo" src="assets/images/logo/logo-white.svg" alt="">
  </div>
  <span class="landing-header__brand">Freebetrange</span>

  <div class="landing-header__menu-container">
    <a class="landing-header__link" [href]="locale | anchorHref : 'pricing'">{{'landing.menu.pricing-item' | translate}}</a>
    <a class="landing-header__link" [href]="locale | anchorHref: 'features'">{{'landing.menu.features-item' | translate}}</a>
    <a class="landing-header__link" [href]="locale | localizedLinks : ExternalLinksType.MANUAL" target="_blank">{{'landing.menu.manual-item' | translate}}</a>
    <a class="landing-header__link" href="https://blog.freebetrange.com/">{{'landing.menu.blog-item' | translate}}</a>
    <a class="landing-header__link" [href]="locale | localizedLinks: ExternalLinksType.STABLES" target="_blank">{{'landing.menu.stables-item' | translate}}</a>
    <a class="landing-header__link hide-sd" [href]="locale | localizedLinks: ExternalLinksType.JOIN_OUR_TEAM">{{'landing.menu.join-team-item' | translate}}</a>
  </div>

  <div class="landing-header__sign-in-container">
    <button class="landing-header__link" (click)="signIn.emit()">{{'landing.menu.sign-up-button' | translate}}</button>
    <button class="landing-header__link" (click)="signIn.emit()">{{'landing.menu.sign-in-button' | translate}}</button>
  </div>

  <button class="landing-header__mobile-menu-button" mat-icon-button (click)="openMenu()">
    <mat-icon>menu</mat-icon>
  </button>
</header>
