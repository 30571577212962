import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'fbr-landing-features',
  templateUrl: './landing-features.component.html',
  styleUrls: ['./landing-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class LandingFeaturesComponent {

  constructor() { }

}
