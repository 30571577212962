import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {UserStore} from '../custom-stores/user.store';
import {Router} from '@angular/router';

@Injectable()
export class UpdateUserInterceptor implements HttpInterceptor {
  constructor(
    @Inject(UserStore) private readonly userStore: UserStore,
    @Inject(Router) private readonly router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: any) => {
        const updateUsers = this._checkUpdateHeader(event as  HttpResponse<any>);
        if (event.type === HttpEventType.Response && (
          (event as  HttpResponse<any>).status ===  202 || updateUsers
        )) {
          this.userStore.updateUser();
        }
      }),
      catchError((err) => this.handleError(err, req, next))
    );
  }

  handleError(error: HttpErrorResponse,request?, next?): Observable<any> {
    if (error.status === 401) {
      this.router.navigate([''])
    }

    return throwError(error)
  }

  private _checkUpdateHeader(res: HttpResponse<any>): boolean {
    try {
      return res.headers.get('Fbr-User-Changed').toString() === 'true'
    } catch (e) {
      return false;
    }
  }
}
