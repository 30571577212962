import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {take, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TranslatedTitleStrategyProvider extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly translate: TranslateService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.translate.get(title).pipe(
        tap((translateStr) => {
          this.title.setTitle(translateStr);
        }),
        take(1)
      ).subscribe();
    }
  }
}
