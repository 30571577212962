<div class="landing-pricing-card">
  <div class="landing-pricing-card__header"
       [class.landing-pricing-card__header_pro]="card.pro">
    <span class="landing-pricing-card__header-content">
      {{(card.pro ? 'landing.pricing.pro-card.title' : 'landing.pricing.free-card.title') | translate}}
      @if (card.percentNumber) {
        <span class="landing-pricing-card__header-percent">
          -{{card.percentNumber}}%
        </span>
      }
    </span>
  </div>
  <button class="landing-pricing-card__link" (click)="signIn.emit()">
    {{card.signUpText | translate}} <span class="landing-pricing-card__link-icon">&#8594;</span>
  </button>
  <div class="landing-pricing-card__body">
    <div class="landing-pricing-card__title">
      @if (card.pricePerMonth) {
        <span>${{card.pricePerMonth}} / {{'landing.pricing.per-month-label' | translate}}</span>
      } @else {
        <span>{{'landing.pricing.free-price' | translate}}</span>
      }

      @if (card.price) {
        <span class="landing-pricing-card__full-price">${{card.price}}</span>
      }
    </div>

    <ul class="landing-pricing-card__body-list">
      @for (feature of card.features; track feature) {
        <li>{{feature | translate}}</li>
      }
    </ul>
  </div>
</div>
