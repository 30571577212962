import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ILandingImageOption} from '../../interfaces/landing-image-option.interface';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

@Component({
  selector: 'fbr-landing-strategies',
  templateUrl: './landing-strategies.component.html',
  styleUrls: ['./landing-strategies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonToggleModule, NgOptimizedImage],

})
export class LandingStrategiesComponent {

  strategiesOptions: ILandingImageOption[] = [{
    title: 'landing.strategies.train.title',
    mobileImgUrl: 'landing-train-tablet2.png',
    imgUrl: 'landing-train-desktop2.png',
    description: 'landing.strategies.train.description',
    alt: 'Preflop range trainer'
  }, {
    title: 'landing.strategies.view.title',
    mobileImgUrl: 'landing-view-tablet.png',
    imgUrl: 'landing-view-desktop.png',
    description: 'landing.strategies.view.description',
    alt: 'Preflop charts viewer'
  }];

  selectedOption: ILandingImageOption = this.strategiesOptions[0];

  constructor() { }

  setOption(option: ILandingImageOption): void {
    if (option) {
      this.selectedOption = option;
    }
  }
}
