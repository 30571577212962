import {
  ChangeDetectionStrategy,
  Component, computed,
  EventEmitter, input,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {ILanguage} from '../../../../common/interfaces/language.interface';
import {ExternalLinksType} from '../../../../common/enum/external-links.enum';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CommonPipesModule} from '../../../../common/pipes/common-pipes.module';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggle} from '@angular/material/button-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'fbr-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CommonPipesModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggle,
    FormsModule
  ],
})
export class LandingFooterComponent implements OnInit {
  languages = input.required<ILanguage[]>();
  selectedLocale = input.required<string>();
  selectedLanguage = computed(() => this.languages().find(l => l.locale === this.selectedLocale()));
  @Output() localeChange = new EventEmitter<string>();

  currentYear: number;

  legalFooterLinks = [
    {
      title: 'landing.footer.links.terms-of-use',
      href: 'https://freebetrange.com/en/terms-of-use'
    },
    {
      title: 'landing.footer.links.refund-policy',
      href: 'https://freebetrange.com/en/refund-policy'
    },
    {
      title: 'landing.footer.links.privacy-policy',
      href: 'https://freebetrange.com/en/privacy-policy'
    }
  ];

  contactFooterLinks = [
    {
      title: 'landing.footer.links.about-us',
      linkType: ExternalLinksType.ABOUT_US
    },
    {
      title: 'landing.footer.links.contact-us',
      linkType: ExternalLinksType.CONTACT_US
    }
  ];

  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

}
