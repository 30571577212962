import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {ILandingPricingCard} from './landing-pricing-card.interface';
import {Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'fbr-landing-pricing-card',
  templateUrl: './landing-pricing-card.component.html',
  styleUrls: ['./landing-pricing-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class LandingPricingCardComponent {
  @Input() card: ILandingPricingCard;
  @Output() signIn = new EventEmitter();

  constructor() { }

}
