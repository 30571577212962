<section class="landing-ranges-source">
  <h2 class="landing__title">{{'landing.ranges-source.title' | translate}}</h2>

  <mat-button-toggle-group class="landing__button-group landing-ranges-source__button-group"
                           hideSingleSelectionIndicator="true"
                           [value]="selectedOption"
                           (valueChange)="setOption($event)">
    @for (option of sourcesOptions; track $index) {
      <mat-button-toggle [value]="option">{{option.title | translate}}</mat-button-toggle>
    }
  </mat-button-toggle-group>

  @for (option of sourcesOptions; track $index) {
    <div [style.visibility]="option === selectedOption ? 'visible' : 'hidden'"
         [style.position]="option === selectedOption ? 'static' : 'absolute'"
         class="landing-ranges-source__mobile-img-wrapper">
      <img class="landing-ranges-source__mobile-img"
           [ngSrc]="option.mobileImgUrl"
           ngSrcset="1x, 2x"
           [alt]="option.alt"
           width="736"
           height="440">
    </div>
  }



  <div class="landing-ranges-source__description">
    <span class="landing-ranges-source__list" [innerHtml]="selectedOption?.description | translate"></span>
  </div>

  @for (option of sourcesOptions; track $index) {
    <div class="landing-ranges-source__img-wrapper"
         [style.visibility]="option === selectedOption ? 'visible' : 'hidden'">
      <img class="landing-ranges-source__img"
           [ngSrc]="option.imgUrl"
           ngSrcset="1x, 2x"
           [alt]="option.alt"
           width="1010"
           height="580">
    </div>
  }
</section>
