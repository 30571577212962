import {Routes} from '@angular/router';
import {RefundPolicyComponent} from '../pages/refund-policy/refund-policy.component';
import {PrivacyPolicyComponent} from '../pages/privacy-policy/privacy-policy.component';
import {TermsOfUseComponent} from '../pages/terms-of-use/terms-of-use.component';
import {UserLoggedGuard} from '../common/guards/userlogged.guard';
import {LandingComponent} from '../pages/landing/landing.component';
import {LanguageRedirectGuard} from '../common/guards/language-redirect.guard';
import {LanguageSetterGuard} from '../common/guards/language-setter.guard';
import {LanguageDetectorComponent} from './language-detector/language-detector.component';

export const routes: Routes = [
  {
    path: ':lang',
    canActivate: [LanguageSetterGuard],
    title: 'main_title',
    children: [
      {
        path: '',
        loadComponent: () => LandingComponent,
      },
      {
        path: 'ranges',
        loadChildren: () => import('../pages/ranges/ranges.module').then(mod => mod.RangesModule),
        canActivate: [UserLoggedGuard]
      },
      {
        path: 'purchase-complete/:invoiceCode',
        loadChildren: () => import('../pages/purchase-complete/purchase-complete.module').then(mod => mod.PurchaseCompleteModule),
      },
      {
        path: 'refund-policy',
        component: RefundPolicyComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent
      }
    ]
  },
  {
    path: '',
    component: LanguageDetectorComponent,
    canActivate: [LanguageRedirectGuard],
    pathMatch: 'full'
  }
];
