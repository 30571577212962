import {inject, Inject, Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  CanActivateFn,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import {DEFAULT_LOCALE, LanguageService} from '../services/language.service';
import { LinkService } from '../services/link.service';
import { DOCUMENT } from '@angular/common';
import {TFbrLang} from '../enum/lang.enum';

export const LanguageSetterGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const languageService =  inject(LanguageService);
  const linkService = inject(LinkService);
  const meta = inject(Meta);
  const document = inject(DOCUMENT);
  const paramLang = route.paramMap.get('lang');

  return languageService
    .setLanguage(paramLang as TFbrLang)
    .then((lang: TFbrLang) => {
      if (lang !== paramLang) {
        return router.createUrlTree([lang], { queryParamsHandling: "merge" });
      } else {
        setLanguageMetadata(languageService, linkService, meta, document, lang);
        return true;
      }
    });
};

function setLanguageMetadata(languageService: LanguageService, linkService: LinkService, meta: Meta, document: Document, lang: string) {
  const currentLanguage =  lang;
  const languageEntity = languageService.supportedLanguages.find(lang => lang.locale === currentLanguage);
  linkService.removeCanonicalLink();
  meta.removeTag(`name='description'`);
  linkService.addTag( { rel: 'canonical', href: languageEntity.canonicalLink});
  document.documentElement.lang = currentLanguage;
  meta.addTag({
    name: 'description',
    lang: currentLanguage,
    content: languageEntity.metaDescription
  });
}
