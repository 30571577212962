<section class="landing-faq">
  <h2 class="landing__title">{{'landing.faq.title' | translate}}</h2>

  <span class="landing-faq__link">
    {{'landing.faq.link-description' | translate}}
    <a [href]="locale | localizedLinks : ExternalLinksType.MANUAL">
      {{'landing.faq.link-href' | translate}}
      <span class="landing-faq__link-icon">&#8594;</span>
    </a>
  </span>

  <mat-accordion class="landing-faq__accordion"
                 [multi]="true">
    <mat-expansion-panel hideToggle #panel_1>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'landing.faq.sections.1.question' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          @if (!panel_1.expanded) {
            <mat-icon>add</mat-icon>
          } @else {
            <mat-icon>close</mat-icon>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>{{'landing.faq.sections.1.answer' | translate}}</div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle #panel_6>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'landing.faq.sections.6.question' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          @if (!panel_6.expanded) {
            <mat-icon>add</mat-icon>
          } @else {
            <mat-icon>close</mat-icon>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <p>{{'landing.faq.sections.6.answer.1' | translate}}</p>
        <div class="landing-faq__img-wrapper" style="max-width: 420px">
          <img ngSrc="landing-100-preflop-range-min.png"
               ngSrcset="1x, 2x"
               class="landing-faq__img"
               alt="100% preflop range"
               width="420"
               height="420">
        </div>

        <p>{{'landing.faq.sections.6.answer.2' | translate}}</p>
        <div class="landing-faq__img-wrapper" style="max-width: 620px;">
          <img ngSrc="landing-sb-vs-btn-min.png"
               ngSrcset="1x, 2x"
               class="landing-faq__img"
               alt="Preflop range: Defend small blind vs button"
               width="800"
               height="420">
        </div>
        <p [innerHTML]="'landing.faq.sections.6.answer.3' | translate"></p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle #panel_2>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'landing.faq.sections.2.question' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          @if (!panel_2.expanded) {
            <mat-icon>add</mat-icon>
          } @else {
            <mat-icon>close</mat-icon>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>{{'landing.faq.sections.2.answer' | translate}}</div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle #panel_3>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'landing.faq.sections.3.question' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          @if (!panel_3.expanded) {
            <mat-icon>add</mat-icon>
          } @else {
            <mat-icon>close</mat-icon>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>{{'landing.faq.sections.3.answer' | translate}}</div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle #panel_4>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'landing.faq.sections.4.question' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          @if (!panel_4.expanded) {
            <mat-icon>add</mat-icon>
          } @else {
            <mat-icon>close</mat-icon>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        {{'landing.faq.sections.4.answer' | translate}}
        <div class="landing-faq__img-wrapper">
          <img ngSrc="landing-open-raise-chart-faq.png"
               ngSrcset="1x, 2x"
               class="landing-faq__img"
               alt="Open raise from the middle position preflop chart"
               width="800"
               height="420">
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle #panel_5>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'landing.faq.sections.5.question' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          @if (!panel_5.expanded) {
            <mat-icon>add</mat-icon>
          } @else {
            <mat-icon>close</mat-icon>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>{{'landing.faq.sections.5.answer' | translate}}</div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
