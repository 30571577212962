import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import {inject} from '@angular/core';
import {UserStore} from '../custom-stores/user.store';
import {CookieService} from 'ngx-cookie-service';


export const UserLoggedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const userStore = inject(UserStore);
  const cookieService = inject(CookieService);

  if (cookieService.check('fbr_id_token') ||  cookieService.check('fbr_refresh_token')) {
    return userStore.getUser()
      .then(() => true)
      .catch(() => false)
  } else {
    return false;
  }
}
